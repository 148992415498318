import React from "react";
import { _getPriorityType, _getStatusType } from "../../../helpers/utils";
import Badge from "../Badge";

import ChevronRightIcon from "@heroicons/react/24/outline/ChevronRightIcon";
import CalendarDaysIcon from "@heroicons/react/24/outline/CalendarDaysIcon";
import { SERVICE_REQUEST_STATUS_OPTIONS } from "../../../constants";

export default function Ticket({
  className = "",
  icon,
  active,
  data,
  onClick,
  btnText,
  ...rest
}) {
  let {
    name,
    location,
    requester,
    helperText,
    helperSubText,
    date,
    status,
    priority,
    node,
    assignedTo,
    category,
  } = data;

  const serviceNameIs = data?.node?.serviceNames;
  const TypeofPartner = data?.node?.type;
  return (
    <>
      <div
        onClick={(e) => onClick(node)}
        {...rest}
        className={` cursor-pointer alert gap-[1px] shadow rounded-md group/item items-start flex-col ${className} ${
          active ? "bg-base-300 ring" : "bg-base-200"
        } hover:bg-base-300 hover:ring duration-300 justify-start transition-all`}
      >
        <div className="w-full">
          {/* {icon || (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="w-6 h-6 stroke-info shrink-0"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
          )} */}
          <div className="w-full ml-1">
            <div className="flex justify-between gap-2">
              <div className="flex w-full text-left">
                <div className="w-full">
                  <div className="flex">
                  <h3 className="w-full overflow-hidden text-sm font-bold text-ellipsis">
                    {name}
                  </h3>
                  {TypeofPartner && (
                    <Badge
                    className={"bg-orange-600"}
                      text={TypeofPartner}
                      color={_getPriorityType(TypeofPartner)}
                    />
                  )}
                  </div>
                  <p className="mr-1 text-xs">
                    {location
                      ? location
                      : assignedTo?.firstName
                      ? `${assignedTo?.firstName} ${assignedTo?.lastName}`
                      : requester?.firstName
                      ? `${requester?.firstName} ${requester?.lastName}`
                      : ""}
                  </p>
                </div>
                <div className="w-full " style={{ textAlign: "right" }}>
                  <p className="text-xs font-semibold">{helperText}</p>
                  <p className="mb-1 text-xs">{helperSubText}</p>
                </div>
              </div>
            </div>
            <div className="flex justify-between w-full pt-1 mt-2">
              <div className="text-xs">
                <div className="inline-flex gap-1">
                  {status && (
                    <Badge
                      text={status
                        ?.split("_")
                        ?.join(" ")
                        ?.replace("SENT", "RECEIVED")}
                      color={_getStatusType(status)}
                    />
                  )}
                  {priority && (
                    <Badge text={priority} color={_getPriorityType(priority)} />
                  )}
                  {category && (
                    <Badge text={category} color={SERVICE_REQUEST_STATUS_OPTIONS?.find(a=>a?.label == category)?.color} />
                  )}
                  {/* {serviceNameIs && (
                    <Badge className={"bg-yellow-600"} text={serviceNameIs} color={_getPriorityType(serviceNameIs)} />
                  )} */}

                  <div className="flex flex-wrap gap-2">
                    {" "}
                    {/* Container to ensure badges wrap */}
                    {serviceNameIs &&
                      serviceNameIs
                        .split(",")
                        .map((service) => service.trim()) // Remove leading/trailing spaces from each service
                        .filter((service) => service.length > 0) // Filter out empty strings and spaces
                        .map((service, index) => (
                          <Badge
                            key={index}
                            className="text-black bg-yellow-600" // Badge with black text and yellow background
                            text={service}
                          />
                        ))}
                  </div>
                </div>
              </div>
              {date ? (
                <div className="inline-flex gap-1">
                  <CalendarDaysIcon className="w-4 h-4" />
                  <p className="text-xs font-semibold">{date}</p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {/* <div
          onClick={() => {
            onClick(node);
          }}
          className="absolute cursor-pointer transition-all duration-300 right-6 group/edit invisible rounded-full px-2 py-1.5 bg-base-200 hover:bg-base-100 group-hover/item:visible"
        >
          <span className="text-xs font-semibold">{btnText || "Details"}</span>
          <ChevronRightIcon className="w-4 h-4 group-hover/edit:translate-x-0.5" />
        </div> */}
      </div>
    </>
  );
}