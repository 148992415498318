import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../slice/authSlice";

export const ContactAdmin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(logout());
    navigate("/login");
  };

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>Contact Admin</h1>
      <p>
        You don't have the required roles to access the platform. Please contact
        the administrator for assistance.
      </p>
      <p>
        Email us at:{" "}
        <a
          href="mailto:email@inspacco.com"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          email@inspacco.com
        </a>
      </p>
      <button
        onClick={logOut}
        style={{ marginTop: "20px", padding: "10px 20px", cursor: "pointer" }}
      >
        Logout
      </button>
    </div>
  );
};
